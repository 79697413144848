import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class SelectElements {

  static #_selectColor = [1, 1, 0]

  static #_isShiftKeyPressed = false;
  static #_selectByShiftListenerKeyUp = () => {};
  static #_selectByShiftListenerKeyDown = () => {};

  static get #_sceneElementsIds() {
    return XeokitMediator.viewer.scene.objectIds;
  }

  static get #_isShowOnlySelectedElements() {
    return XeokitMediator.ElementsSelection.isShowOnlySelectedElements;
  }

  static get #_isHideSelectedElements() {
    return XeokitMediator.ElementsSelection.isHideSelectedElements;
  }

  static get #_selectedElements() {
    return XeokitMediator.ElementsSelection.selectedElements;
  }

  static get #_pickedElement() {
    return XeokitMediator.ElementsSelection.pickedElement;
  }

  /** Выбор элементов модели
   * @param {Array<String>} [selectElementUuids = []] Массив UUID'ов выделяемых элементов
  */
  static selectElements(selectElementUuids = []) {
    XeokitMediator.ElementsSelection.setSelectedElements(selectElementUuids)

    if (!this.#_isHideSelectedElements && !this.#_isShowOnlySelectedElements) {
      XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_sceneElementsIds, false)
      XeokitMediator.ElementsSettings.setElementsHighlighted(selectElementUuids, true, this.#_selectColor)
    }
    else if (this.#_isHideSelectedElements) this.hideSelectedElements();
    else if (this.#_isShowOnlySelectedElements) this.showOnlySelectedElements();
  
  }

  /** Снятие выбора элементов модели
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов выделяемых элементов
  */
  static unselectElements(elementUuids = []) {
    let elementsToSelect = new Set(this.#_selectedElements)

    for(let i = 0; i < this.#_selectedElements.length; i++ ){
      if(elementsToSelect.has(elementUuids[i])){
        elementsToSelect.delete(elementUuids[i])
      }
    }
    
    this.selectElements(Array.from(elementsToSelect))
  }

  static #_getValueByIndex(set, index) {
    let ind = 0
    let res

    for (let value of set) {
        if (ind === index) {
            res = value
            break
        }
        ind++
    }
    return res
  }

  /** Последовательный выбор элементов модeли
   * @param {Array<String>} [elementUuids = null] UUID первого/следующего выделяемого элемента
  */
  static serialSelectElements(elementUuids) {
    const isSelected = this.#_selectedElements?.includes(elementUuids);
    let selectElements
    if(isSelected) {
      selectElements = this.#_selectedElements.filter(item => item !== elementUuids)
    }
    else {
      selectElements = [...this.#_selectedElements, elementUuids]
      if(this.#_pickedElement != null && !this.#_selectedElements?.includes(this.#_pickedElement)) {
        selectElements = [...selectElements, this.#_pickedElement]
      }
    }
    this.selectElements(selectElements);

    selectElements = null
    if (this.#_isShowOnlySelectedElements || this.#_isHideSelectedElements) {
      XeokitMediator.ElementsSettings.setElementsVisible(elementUuids, false);
      this.pickElement(null);
    }
  }

  /** Выбор активного элемента модели
   * @param {Array<String>} [elementUuid = null] UUID выделяемого активного элемента
  */
  static pickElement(elementUuid = null) {
    if (this.#_pickedElement) {
      XeokitMediator.ElementsSettings.setElementsSelected(this.#_pickedElement, false);
    }
    if (this.#_pickedElement == elementUuid) elementUuid = null

    XeokitMediator.ElementsSelection.setPickedElement(elementUuid);
    if (elementUuid) {
      XeokitMediator.ElementsSettings.setElementsSelected(elementUuid, true);
    }
  }

  /** Скрыть выделенные элементы */
  static hideSelectedElements() {
    XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_sceneElementsIds, false)

    XeokitMediator.ElementsSettings.setElementsVisible(this.#_sceneElementsIds, true)
    XeokitMediator.ElementsSettings.setElementsVisible(this.#_selectedElements, false)
  }

  /** Отобразить только выделенные элементы */
  static showOnlySelectedElements() {
    XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_sceneElementsIds, false)
    XeokitMediator.ElementsSettings.setElementsVisible(this.#_sceneElementsIds, false)

    XeokitMediator.ElementsSettings.setElementsVisible(this.#_selectedElements, true)
  }

  /** Отобразить все элементы */
  static showAllElements() {
    XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_sceneElementsIds, false)
    XeokitMediator.ElementsSettings.setElementsVisible(this.#_sceneElementsIds, true)
    XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_selectedElements, true, this.#_selectColor)
  }

  static dropAll() {
    XeokitMediator.ElementsSettings.setElementsHighlighted(this.#_sceneElementsIds, false)
    XeokitMediator.ElementsSettings.setElementsVisible(this.#_sceneElementsIds, true)

    XeokitMediator.ElementsSelection.setHideSelectedElements(false)
    XeokitMediator.ElementsSelection.setShowOnlySelectedElements(false)

    XeokitMediator.ElementsSettings.setElementsXRayed(this.#_sceneElementsIds, false)
    this.selectElements([])
    this.pickElement(null)
  }

  static onSelectByShift() {
    document.addEventListener("keydown", this.#_selectByShiftListenerKeyDown = (event) => {
      if (event.shiftKey) {
        if(!this.#_isShiftKeyPressed) {
          this.#_isShiftKeyPressed = true

          let elementIndex = this.#_selectedElements.indexOf(this.#_pickedElement);
          if (elementIndex !== -1) {
            let selectedElements = [...this.#_selectedElements]
            selectedElements.splice(elementIndex, 1);
            this.selectElements(selectedElements)
          }
          else {
            let selectedElements = [...this.#_selectedElements, this.#_pickedElement]
            this.selectElements(selectedElements)
          }
          if (this.#_pickedElement && store.state.axis.tree.node) {
            store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', this.#_pickedElement)
          }
        }
      }
    })

    document.addEventListener("keyup", this.#_selectByShiftListenerKeyUp = () => {
      if (this.#_isShiftKeyPressed) {
        this.#_isShiftKeyPressed = false
      }
    })
  }

  static selectByShiftRemove() {
    document.removeEventListener("keydown", this.#_selectByShiftListenerKeyDown)
    document.removeEventListener("keyup", this.#_selectByShiftListenerKeyUp)
  }
}