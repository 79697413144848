import { Control } from "@xeokit/xeokit-sdk/dist/xeokit-sdk.es";
import { math } from "@xeokit/xeokit-sdk";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { geometry } from "../plugins/geometry/geometry";
class XeokitControl extends Control {

    constructor(plugin){
        super(plugin)

        this._angleThreshold = 5
    }

    _bindEvents() {

        const self = this;

        var grabbed = false;

        const DRAG_ACTIONS = {
            none: -1,
            xTranslate: 0,
            yTranslate: 1,
            zTranslate: 2,
            xRotate: 3,
            yRotate: 4,
            zRotate: 5
        };

        const rootNode = this._rootNode;

        var nextDragAction = null; // As we hover grabbed an arrow or hoop, self is the action we would do if we then dragged it.
        var dragAction = null; // Action we're doing while we drag an arrow or hoop.
        const lastCanvasPos = math.vec2();

        const xBaseAxis = math.vec3([1, 0, 0]);
        const yBaseAxis = math.vec3([0, 1, 0]);
        const zBaseAxis = math.vec3([0, 0, 1]);

        const canvas = this._viewer.scene.canvas.canvas;
        const camera = this._viewer.camera;
        const scene = this._viewer.scene;

        { // Keep gizmo screen size constant

            const tempVec3a = math.vec3([0, 0, 0]);

            let distDirty = true;
            let lastDist = -1;

            this._onCameraViewMatrix = scene.camera.on("viewMatrix", () => {
                distDirty = true;
            });

            this._onCameraProjMatrix = scene.camera.on("projMatrix", () => {
                distDirty = true;
            });
            distDirty
            this._onSceneTick = scene.on("tick", () => {
                
                const dist = Math.abs(math.lenVec3(math.subVec3(scene.camera.eye, this._pos, tempVec3a)));
                if (dist !== lastDist) {
                    if (camera.projection === "perspective") {
                        const worldSize = (Math.tan(camera.perspective.fov * math.DEGTORAD)) * dist;
                        const size = 0.07 * worldSize;
                        rootNode.scale = [size, size, size];
                        lastDist = dist;

                        if (lastDist < 5) {
                            this._angleThreshold = 1
                        }
                        else if (lastDist >= 5 && lastDist < 50) {
                            this._angleThreshold = 2.5
                        }
                        else this._angleThreshold = 5
                    }
                }

                if (camera.projection === "ortho") {
                    const worldSize = camera.ortho.scale / 10.5;
                    const size = worldSize;
                    rootNode.scale = [size, size, size];
                    lastDist = dist;

                    if (camera.ortho.scale < 10) {
                        this._angleThreshold = 1
                    }
                    else if (camera.ortho.scale >= 10 && camera.ortho.scale < 100) {
                        this._angleThreshold = 2.5
                    }
                    else this._angleThreshold = 5
                }
            });
        }

        const getClickCoordsWithinElement = (function () {
            const canvasPos = new Float64Array(2);
            return function (event) {
                if (!event) {
                    event = window.event;
                    canvasPos[0] = event.x;
                    canvasPos[1] = event.y;
                } else {
                    var element = event.target;
                    var totalOffsetLeft = 0;
                    var totalOffsetTop = 0;

                    while (element.offsetParent) {
                        totalOffsetLeft += element.offsetLeft;
                        totalOffsetTop += element.offsetTop;
                        element = element.offsetParent;
                    }
                    canvasPos[0] = event.pageX - totalOffsetLeft;
                    canvasPos[1] = event.pageY - totalOffsetTop;
                }
                return canvasPos;
            };
        })();

        const localToWorldVec = (function () {
            const mat = math.mat4();
            return function (localVec, worldVec) {
                math.quaternionToMat4(self._rootNode.quaternion, mat); // quaternion здесь описывает начальный поворот сечения относительно его центра
                math.transformVec3(mat, localVec, worldVec); // Задается вектор направления движения в глобальном пространстве
                math.normalizeVec3(worldVec); // Бесполезно???
                return worldVec;
            };
        })();

        // var getTranslationPlane = (function () {
        //     const planeNormal = math.vec3();
        //     return function (worldAxis) {
        //         //const absX = Math.abs(worldAxis[0]);
        //         //const absY = Math.abs(worldAxis[1]);
        //         //const absZ = Math.abs(worldAxis[2]);
        //         // if (absX > absY && absX > absZ) {
        //         //     math.cross3Vec3(worldAxis, [0, 1, 0], planeNormal); // Поиск вектора, перпендикулярного для каждого из двух векторов
        //         // } else {
        //         //     math.cross3Vec3(worldAxis, [1, 0, 0], planeNormal);
        //         // }
        //         math.cross3Vec3(planeNormal, worldAxis, planeNormal);
        //         math.normalizeVec3(planeNormal);

        //         return planeNormal;
        //     }
        // })();

        const dragTranslateSectionPlane = (function () {
            XeokitMediator.viewer.scene.camera.ortho.far = 2000
            XeokitMediator.viewer.scene.camera.ortho.near = 0.01
            const p1 = math.vec3();
            const p2 = math.vec3();
            const worldAxis = math.vec4();
            return function (baseAxis, fromMouse, toMouse) { // Передаем текущую ось и две canvas позиции
                localToWorldVec(baseAxis, worldAxis); // Определяем вектор движения в мировом пространстве

                //const planeNormal = getTranslationPlane(worldAxis, fromMouse, toMouse); // Должен возвращать плоскость, наиболее подходящую для движения по выбранной оси. Возвращает непойми что.
                //planeNormal

                let viewMatrix = camera.viewMatrix
                const planeNormal2 = [viewMatrix[2], viewMatrix[6], viewMatrix[10]];
                
                getPointerPlaneIntersect(fromMouse, planeNormal2, p1);
                getPointerPlaneIntersect(toMouse, planeNormal2, p2);
                
                
                // self._pos[0] += worldAxis[0] * dot;
                // self._pos[1] += worldAxis[1] * dot;
                // self._pos[2] += worldAxis[2] * dot;
                
                math.subVec3(p2, p1);
                let dot = math.dotVec3(p2, worldAxis);

                if(XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
                    
                    self._pos[0] += worldAxis[0] * dot;
                    self._pos[1] += worldAxis[1] * dot;
                    self._pos[2] += worldAxis[2] * dot;
                }
                else {
                    const difference = math.vec3([0, 0, 0]);
                    const camEye = camera._eye
                    const tempDif = math.vec3()
                    math.subVec3(scene.camera.eye, self._pos, tempDif)
                    const difLen = math.lenVec3(tempDif)
                    const newCamEye = [camEye[0] + 20000 * tempDif[0] / -difLen, camEye[1] + 20000 * tempDif[1] / -difLen, camEye[2] + 20000 * tempDif[2] / -difLen]
                    
                    math.subVec3(newCamEye, self._pos, difference)
                    const dist = math.lenVec3(difference)
                    
                    //math.subVec3(scene.camera.eye, self._pos, difference);
                    //const dist = Math.abs(math.dotVec3(self._sectionPlane.dir, difference));
                    //const dist2 =  math.dotVec3(self._sectionPlane.dir, difference);
                    
                    
                    // if(!self._sectionPlane.isSectionCube){
                    //     const sectionPlaneDirInverse = math.vec3()
                    //     math.mulVec3Scalar(self._sectionPlane.dir, -1, sectionPlaneDirInverse)
                    //     const denom = math.dotVec3(self._sectionPlane.dir, sectionPlaneDirInverse); 
                    //     const d = -math.dotVec3(self._pos, self._sectionPlane.dir);
                    //     const centerMinusRayO = math.vec3()
                    //     math.subVec3(self._pos, scene.camera.eye, centerMinusRayO)
                    //     const t = (math.dotVec3(self._sectionPlane.dir, centerMinusRayO) + d) / denom;
                    //     t
                    //     // if(t >= 0) { // Проверка, камера перед сечением или позади
                    //     //     console.log("t < 0")
                    //     //     dot = dot * -1
                    //     // }
                    //     // const dalnost = self._sectionPlane.dist
                    //     // const dir = self._sectionPlane.dir
                    //     // const cam = scene.camera.eye
                    //     // if(dir[0] * cam[0] + dir[1] * cam[1] + dir[2] * cam[2] + dalnost > 0) {
                    //     //     console.log("inv")
                    //     //     dot = dot * -1
                    //     // }
                    // }
                    
                    // if(math.dotVec3(self._sectionPlane.dir, difference) > 0) { //Проверка, проскочил ли camera._eye сечение, или нет
                    // //if(dist > 0) {
                    //     math.subVec3(p1, p2);
                    //     dot = math.dotVec3(p1, worldAxis);

                    //     console.log(dot)
                    //     console.log("Инверт")
                    //     dot = dot * -1
                    // }
                    // else{
                    //     math.subVec3(p2, p1);
                    //     dot = math.dotVec3(p2, worldAxis);

                        
                    // }
                    

                    dot = dot * (20000 / dist)
                    
                    self._pos[0] += worldAxis[0] * dot;
                    self._pos[1] += worldAxis[1] * dot;
                    self._pos[2] += worldAxis[2] * dot;
                }
                self._rootNode.position = self._pos;
                if (self._sectionPlane) {
                    self._sectionPlane.pos = self._pos;
                }
            }
        })();
        
        var dragRotateSectionPlane = (function () {

            XeokitMediator.viewer.scene.camera.ortho.far = 2000
            XeokitMediator.viewer.scene.camera.ortho.near = 0.01
            const p1 = math.vec4();
            const p2 = math.vec4();
            const c = math.vec4();
            const worldAxis = math.vec4();
            
            return function (baseAxis, fromMouse, toMouse) {
                
                localToWorldVec(baseAxis, worldAxis);
                const hasData = getPointerPlaneIntersect(fromMouse, worldAxis, p1) && getPointerPlaneIntersect(toMouse, worldAxis, p2);
                if (!hasData) { // Find intersections with view plane and project down to origin
                    
                    let viewMatrix = camera.viewMatrix
                    const planeNormal = [viewMatrix[2], viewMatrix[6], viewMatrix[10]];
                    //const planeNormal = getTranslationPlane(worldAxis, fromMouse, toMouse);
                    getPointerPlaneIntersect(fromMouse, planeNormal, p1, 1); // Ensure plane moves closer to camera so angles become workable
                    getPointerPlaneIntersect(toMouse, planeNormal, p2, 1);
                    
                    var dot = math.dotVec3(p1, worldAxis);
                    p1[0] -= dot * worldAxis[0];
                    p1[1] -= dot * worldAxis[1];
                    p1[2] -= dot * worldAxis[2];
                    dot = math.dotVec3(p2, worldAxis);
                    p2[0] -= dot * worldAxis[0];
                    p2[1] -= dot * worldAxis[1];
                    p2[2] -= dot * worldAxis[2];
                }

                // const tempVec3a = math.vec3()
                // const offsetVec = math.vec3()
                // const projVec = math.vec3()
                // math.subVec3(self._pos, [0, 0, 0], offsetVec)
                // //console.log(offsetVec)
                // math.mulVec4(offsetVec, self._sectionPlane.dir, tempVec3a)
                // math.mulVec3Scalar(tempVec3a, (1 / math.lenVec3(self._sectionPlane.dir)), projVec)
                // console.log("projVec", projVec)

                math.normalizeVec3(p1);
                math.normalizeVec3(p2);
                dot = math.dotVec3(p1, p2);
                dot = math.clamp(dot, -1.0, 1.0); // Rounding errors cause dot to exceed allowed range
                
                geometry
                // Когда сечение привязано к плоскости xy необходимо создать накопительный эффект - как только
                // теоретический поворот становится больше гранично - повернуть на это значение
                var incDegrees = Math.acos(dot) * math.RADTODEG;
                
                math.cross3Vec3(p1, p2, c);
                if (math.dotVec3(c, worldAxis) < 0.0) {
                    incDegrees = -incDegrees;
                }
                sectionPlaneRotationController(incDegrees, baseAxis)
                
            }
        })();

        var sectionPlaneRotationController = (function() {
            
            // Накопительный градус для удержания в зоне магнита
            let deg = 0
            return (incDegrees, baseAxis) => {
                
                // Игнорирование поворота вокруг z
                if (baseAxis[0] == 1 || baseAxis[1] == 1) {
                    
                    // Поиск угла во время поворотов
                    if (geometry.nearestCoordFinder.isAngleBetweenTwoPlanesLessThanValue(self._sectionPlane.dir, [0, 0, 1], self._angleThreshold)) {
                        
                        // Удержание градуса
                        if (( deg < self._angleThreshold * 2 && deg > -self._angleThreshold * 2 )) {

                            deg += incDegrees
                            let zRotation = self._rootNode.rotation[2] * math.RADTODEG
                            let newRotation = []

                            if (self._sectionPlane.dir[2] < 0) newRotation = [180, 0, zRotation]
                            else newRotation = [0, 0, zRotation]

                            let tempVec4 = math.vec4()
                            let quaternion = math.eulerToQuaternion(newRotation, "XYZ", tempVec4)
                            
                            self._rootNode.quaternion = quaternion
                            
                            rotateSectionPlane(); 
                        }

                        // Выход из удержания
                        else {
                            self._rootNode.rotate(baseAxis, (self._angleThreshold + 0.1) * (deg / Math.abs(deg)));
                            rotateSectionPlane(); 
                            deg = 0
                        }
                    }

                    // Обычный поворот
                    else {
                        deg = 0
                        self._rootNode.rotate(baseAxis, incDegrees);
                        rotateSectionPlane();
                    }
                }

                // Обычный поворот
                else {
                    self._rootNode.rotate(baseAxis, incDegrees);
                    rotateSectionPlane();
                }
            }
        })();

        var getPointerPlaneIntersect = (function () {
            const rayOrigin = math.vec4([0, 0, 0, 1]);
            const matrix = math.mat4();
            return function (mouse, planeAxis, dest, offset) {
                offset = offset || 0;
                const { height: realHeight, width: realWidth } = canvas.getBoundingClientRect()
                rayOrigin[0] = mouse[0] * (2 / realWidth) - 1.0;
                rayOrigin[1] = -mouse[1] * (2 / realHeight) + 1.0;
                rayOrigin[2] = 0.0;
                rayOrigin[3] = 1.0;
                math.mulMat4(camera.projMatrix, camera.viewMatrix, matrix); // Unproject norm device coords to view coords
                math.inverseMat4(matrix);
                math.transformVec4(matrix, rayOrigin, rayOrigin);
                math.mulVec4Scalar(rayOrigin, 1 / rayOrigin[3]); // This is now point A on the ray in world space
                
                if(XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
                    const rayDir = camera._eye
                    const planeOrigin = self._sectionPlane.pos; // Plane origin:
                    math.subVec4(rayOrigin, rayDir, rayOrigin); // Приведение rayOrigin к camera._eye
                
                    const d = -math.dotVec3(planeOrigin, planeAxis) - offset; // Верно
                    const dot = math.dotVec3(planeAxis, rayOrigin); // Возможно заменить Dir на Origin
                    if (Math.abs(dot) > 0.00000001) {
                        const t = -(math.dotVec3(planeAxis, rayDir) + d) / dot;
                        math.mulVec3Scalar(rayOrigin, t, dest);
                        math.addVec3(dest, rayDir);
                        math.subVec3(dest, planeOrigin, dest); // приводит новую точку к исходной позиции плоскости

                        return true;
                    }
                    return false;
                }
                else {
                    const camEye = camera._eye
                    const difference = math.vec3()
                    math.subVec3(scene.camera.eye, self._pos, difference)
                    const difLen = math.lenVec3(difference)
                    const rayDir = [camEye[0] + 20000 * difference[0] / -difLen, camEye[1] + 20000 * difference[1] / -difLen, camEye[2] + 20000 * difference[2] / -difLen]
                    
                    const planeOrigin = self._sectionPlane.pos; // Plane origin

                    math.subVec4(rayOrigin, rayDir, rayOrigin); // Приведение rayOrigin к camera._eye
                    
                    const d = -math.dotVec3(planeOrigin, planeAxis) - offset; // Верно
                    const dot = math.dotVec3(planeAxis, rayOrigin); // Возможно заменить Dir на Origin
                    
                    if (Math.abs(dot) > 0.00000001) {
                        const t = -(math.dotVec3(planeAxis, rayDir) + d) / dot;
                        math.mulVec3Scalar(rayOrigin, t, dest);
                        math.addVec3(dest, rayDir);
                        math.subVec3(dest, planeOrigin, dest); // приводит новую точку к исходной позиции плоскости 

                        return true;
                    }
                    return false;
                }
                // console.log([rayOrigin[0], rayOrigin[1], rayOrigin[2]])
                //console.log(camera._eye)
                //let viewMat = camera.viewMatrix
                //console.log(camera)
                //var rayDir = [camera._eye[0] * 2000, camera._eye[1] * 2000, camera._eye[2] * 2000]



                // const rayDir = camera._eye
                // const planeOrigin = self._sectionPlane.pos; // Plane origin:
                // math.subVec4(rayOrigin, rayDir, rayOrigin); // Какой-то аналог нормализации // есть изменение
                
                // const d = -math.dotVec3(planeOrigin, planeAxis) - offset; // Верно
                // const dot = math.dotVec3(planeAxis, rayOrigin); // Возможно заменить Dir на Origin
                // //console.log(camera._ortho._scale)
                // if (Math.abs(dot) > 0.00000001) {
                //     const t = -(math.dotVec3(planeAxis, rayDir) + d) / dot;
                //     math.mulVec3Scalar(rayOrigin, t, dest);
                //     math.addVec3(dest, rayDir);
                //     math.subVec3(dest, planeOrigin, dest); // приводит новую точку к исходной позиции плоскости
                //     return true;
                // }
                // return false;
            }
        })();

        const rotateSectionPlane = (function () {
            const dir = math.vec3();
            const mat = math.mat4();
            return function () {
                if (self.sectionPlane) {
                    math.quaternionToMat4(rootNode.quaternion, mat);  // << ---
                    math.transformVec3(mat, [0, 0, 1], dir);
                    self._setSectionPlaneDir(dir);
                }
            };
        })();

        {
            var mouseDownLeft;
            var mouseDownMiddle;
            var mouseDownRight;
            var down = false;
            var lastAffordanceMesh;
            mouseDownLeft, mouseDownMiddle, mouseDownRight

            this._onCameraControlHover = this._viewer.cameraControl.on("hoverEnter", (hit) => {
                if (!this._visible) {
                    return;
                }
                if (down) {
                    return;
                }
                grabbed = false;
                if (lastAffordanceMesh) {
                    lastAffordanceMesh.visible = false;
                }
                var affordanceMesh;
                const meshId = hit.entity.id;
                switch (meshId) {

                    case this._displayMeshes.xAxisArrowHandle.id:
                        affordanceMesh = this._affordanceMeshes.xAxisArrow;
                        nextDragAction = DRAG_ACTIONS.xTranslate;
                        break;

                    case this._displayMeshes.xAxisHandle.id:
                        affordanceMesh = this._affordanceMeshes.xAxisArrow;
                        nextDragAction = DRAG_ACTIONS.xTranslate;
                        break;

                    case this._displayMeshes.yAxisArrowHandle.id:
                        affordanceMesh = this._affordanceMeshes.yAxisArrow;
                        nextDragAction = DRAG_ACTIONS.yTranslate;
                        break;

                    case this._displayMeshes.yShaftHandle.id:
                        affordanceMesh = this._affordanceMeshes.yAxisArrow;
                        nextDragAction = DRAG_ACTIONS.yTranslate;
                        break;

                    case this._displayMeshes.zAxisArrowHandle.id:
                        affordanceMesh = this._affordanceMeshes.zAxisArrow;
                        nextDragAction = DRAG_ACTIONS.zTranslate;
                        break;

                    case this._displayMeshes.zAxisHandle.id:
                        affordanceMesh = this._affordanceMeshes.zAxisArrow;
                        nextDragAction = DRAG_ACTIONS.zTranslate;
                        break;

                    case this._displayMeshes.xCurveHandle.id:
                        affordanceMesh = this._affordanceMeshes.xHoop;
                        nextDragAction = DRAG_ACTIONS.xRotate;
                        break;

                    case this._displayMeshes.yCurveHandle.id:
                        affordanceMesh = this._affordanceMeshes.yHoop;
                        nextDragAction = DRAG_ACTIONS.yRotate;
                        break;

                    case this._displayMeshes.zCurveHandle.id:
                        affordanceMesh = this._affordanceMeshes.zHoop;
                        nextDragAction = DRAG_ACTIONS.zRotate;
                        break;

                    default:
                        nextDragAction = DRAG_ACTIONS.none;
                        return; // Not clicked an arrow or hoop
                }
                if (affordanceMesh) {
                    affordanceMesh.visible = true;
                }
                lastAffordanceMesh = affordanceMesh;
                grabbed = true;
            });

            this._onCameraControlHoverLeave = this._viewer.cameraControl.on("hoverOut", () => {
                if (!this._visible) {
                    return;
                }
                if (lastAffordanceMesh) {
                    lastAffordanceMesh.visible = false;
                }
                lastAffordanceMesh = null;
                nextDragAction = DRAG_ACTIONS.none;
            });

            canvas.addEventListener("mousedown", this._canvasMouseDownListener = (e) => {
                e.preventDefault();
                if (!this._visible) {
                    return;
                }
                if (!grabbed) {
                    return;
                }
                this._viewer.cameraControl.pointerEnabled = false;
                switch (e.which) {
                    case 1: // Left button
                        mouseDownLeft = true;
                        down = true;
                        var canvasPos = getClickCoordsWithinElement(e);
                        dragAction = nextDragAction;
                        lastCanvasPos[0] = canvasPos[0];
                        lastCanvasPos[1] = canvasPos[1];
                        break;

                    default:
                        break;
                }
            });

            canvas.addEventListener("mousemove", this._canvasMouseMoveListener = (e) => {
                if (!this._visible) {
                    return;
                }
                if (!down) {
                    return;
                }
                var canvasPos = getClickCoordsWithinElement(e);
                const x = canvasPos[0];
                const y = canvasPos[1];

                switch (dragAction) {
                    case DRAG_ACTIONS.xTranslate:
                        dragTranslateSectionPlane(xBaseAxis, lastCanvasPos, canvasPos);
                        break;
                    case DRAG_ACTIONS.yTranslate:
                        dragTranslateSectionPlane(yBaseAxis, lastCanvasPos, canvasPos);
                        break;
                    case DRAG_ACTIONS.zTranslate:
                        dragTranslateSectionPlane(zBaseAxis, lastCanvasPos, canvasPos);
                        break;
                    case DRAG_ACTIONS.xRotate:
                        dragRotateSectionPlane(xBaseAxis, lastCanvasPos, canvasPos);
                        break;
                    case DRAG_ACTIONS.yRotate:
                        dragRotateSectionPlane(yBaseAxis, lastCanvasPos, canvasPos);
                        break;
                    case DRAG_ACTIONS.zRotate:
                        dragRotateSectionPlane(zBaseAxis, lastCanvasPos, canvasPos);
                        break;
                }

                lastCanvasPos[0] = x;
                lastCanvasPos[1] = y;
            });

            canvas.addEventListener("mouseup", this._canvasMouseUpListener = (e) => {
                if (!this._visible) {
                    return;
                }
                this._viewer.cameraControl.pointerEnabled = true;
                if (!down) {
                    return;
                }
                switch (e.which) {
                    case 1: // Left button
                        mouseDownLeft = false;
                        break;
                    case 2: // Middle/both buttons
                        mouseDownMiddle = false;
                        break;
                    case 3: // Right button
                        mouseDownRight = false;
                        break;
                    default:
                        break;
                }
                down = false;
                grabbed = false;
            });

            canvas.addEventListener("wheel", this._canvasWheelListener = (e) => {
                if (!this._visible) {
                    return;
                }
                var delta = Math.max(-1, Math.min(1, -e.deltaY * 40));
                if (delta === 0) {
                    return;
                }
            });
        }
    }
}

export {XeokitControl}