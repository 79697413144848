import { DirLight } from '@xeokit/xeokit-sdk'
import { AmbientLight } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { Mesh } from '@xeokit/xeokit-sdk'
import { VBOGeometry } from '@xeokit/xeokit-sdk'
import { buildSphereGeometry } from '@xeokit/xeokit-sdk'
import { PhongMaterial } from '@xeokit/xeokit-sdk'
import { Node } from '@xeokit/xeokit-sdk'
import store from "@/store";

const _color = [1, 1, 1]

/*eslint-disable no-dupe-class-members*/
export class BimLights  {

  static #_listSphere = []

  static get #_viewer() {
    return XeokitMediator.viewer
  }

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_lights() {
    return XeokitMediator.viewer.scene.lights
  }

  static get #_color() {
    return _color
  }

  static get #_lightSettings() {
    return XeokitMediator.BimLights.lightSettings
  }

  static loadLights () {
    if (this.#_lightSettings.length === 0) {
      let lightSettings = []
      for (let key in this.#_lights) {
        this.#_lights[key].id = "__" + this.#_lights[key].type + "-" + this.#_genUUID()
        let obj = this.#_createLightObject(this.#_lights[key])
        lightSettings.push(obj)
      }

      this.#_updateSettingsProjectLights(lightSettings)
    }
    else if (this.#_lightSettings.length > 0) {
      this.#_scene.clearLights();
      let isClear = false
      
      // TODO AmbientLight должен быть первым, иначе не работает, выяснить почему так
      // this.#_lightSettings.forEach(light => {
      this.#_lightSettings.toSorted((a, b) => a.type.localeCompare(b.type)).forEach(light => {
        if (light.id.startsWith("__") && !this.#_lights[light.id]) {
          let ids = []
          for (let key in this.#_lights) {
            ids.push(this.#_lights[key].id)
          }

          if (!ids.includes(light.id) && (light.type === "DirLight" || light.type === "AmbientLight")) {
            if (!isClear) {
              this.#_viewer.scene.clearLights();
              isClear = true
            }
            if (light.type === "DirLight") new DirLight(this.#_scene, light);
            if (light.type === "AmbientLight") new AmbientLight(this.#_scene, light);
          }
        }
        else {
          new DirLight(this.#_scene, light);

          let [x, y, z] = light.dir
          let id = light.id.replace('dirLight', 'node').replace('pointLight', 'node')
          let nodeSphere = this.#_createNode(id, [-x, -y, -z], this.#_createNodeChildren(light.color))
          this.#_listSphere.push(nodeSphere)
        }
      })
    }
  }

  static setColorLight (light, lightColor) {
    let {r, g, b} = lightColor
    let rgb = [r, g, b].map(val => +(val / 255).toFixed(2) )
    light.color = rgb

    let id = light.id.replace('dirLight', 'node').replace('pointLight', 'node')
    this.#_listSphere.forEach(nodeSphere => {
      if (nodeSphere.id === id) nodeSphere.children.forEach(mesh => mesh.colorize = [ ...rgb, mesh.colorize[3] ])
    })

    this.updateSetting(light)
  }

  static addDirLight (pos = null) {
    let [x, y, z] = pos ?? this.#_viewer.camera.eye
    let viewMat = this.#_viewer.camera.viewMatrix
    let dirVec = [-viewMat[2], -viewMat[6], -viewMat[10]];
    let lightSettings = this.#_lightSettings
    let uuid = this.#_genUUID()

    let nodeSphere = this.#_createNode(`node-${uuid}`, [x, y, z], this.#_createNodeChildren())
    this.#_listSphere.push(nodeSphere)

    let dirLight = this.#_createDirLight(`dirLight-${uuid}`, dirVec)

    lightSettings.push(this.#_createLightObject(dirLight))
    this.#_updateSettingsProjectLights(lightSettings)
  }

  static destroyDirLight (light) {
    let lightId = light.id
    let id = lightId.replace('dirLight', 'node').replace('pointLight', 'node')

    this.#_listSphere = this.#_listSphere.filter(nodeSphere => {
      if (nodeSphere.id === id) {
        for (let key in nodeSphere.children) {
          nodeSphere.children[key].destroy()
        }
        nodeSphere.destroy()
      }
      else 
        return nodeSphere
    })
    if (light.deleted) this.#_lights[lightId]?.destroy()

    this.#_deleteLightSetting(light.id)
  }

  static updateSetting (light) {
    let obj = this.#_createLightObject(light)
    let lightSettings = this.#_lightSettings
    if (light.id in this.#_lights) {
      this.#_lights[light.id].intensity = light.intensity
      this.#_lights[light.id].color = light.color
    }

    if (this.#_lightSettings.length > 0 && this.#_lightSettings.find(l => l.id === light.id)) {
      lightSettings = this.#_lightSettings.map(l => {
        if (l.id === light.id) l = obj
        return l
      })
    }
    else {
      lightSettings.push(obj)
    }

    this.#_updateSettingsProjectLights(lightSettings)
  }

  static #_createDirLight (uuid, dir) {
    if (this.#_lights[uuid]) {
      return this.#_lights[uuid]
    }

    return new DirLight(this.#_scene, {
            id: uuid,  
            dir: dir,
            color: this.#_color,
            intensity: 1,
            space: "space",
          });
  }
  
  static #_createNodeChildren(color = null) {
    return [
        this.#_createMesh(.3, .8),
        this.#_createMesh(.6, .4, color),
        this.#_createMesh(1.2, .2, color),
        this.#_createMesh(2.4, .1, color),
      ]
  }

  static #_createMesh (radius, opacity, color = null) {
    let viewer = this.#_viewer
    return new Mesh(viewer.scene, {
            geometry: new VBOGeometry(viewer.scene, buildSphereGeometry({ radius })),
            opacity,
            material: new PhongMaterial(viewer.scene, {emissive: color ?? this.#_color, diffuse: [0, 0, 0]}),
            pickable: false,
            collidable: false,
            clippable: false,
          })
  }

  static #_createNode (uuid, position, children) {
    if (this.#_scene.components[uuid]) {
      return this.#_scene.components[uuid]
    }

    return new Node(this.#_scene, {
            id: uuid, position, children,
            pickable: false,
            visible: true,
            collidable: false,
            clippable: false,
          });
  }

  static #_deleteLightSetting(id) {
    this.#_updateSettingsProjectLights(this.#_lightSettings.filter(l => l.id !== id))
  }

  static #_createLightObject (dirLight) {
    let {id, intensity, type, space, color, dir} = dirLight

    let [r, g, b] = color
    let [x, y, z] = dir ?? []

    return {
      id, type, 
      space: space ?? "space",
      intensity: +intensity, 
      color: [r, g, b] ?? [], 
      dir: [x, y, z] ?? [],
    }
  }

  static #_genUUID () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  static #_updateSettingsProjectLights(lightSettings) {
    store.dispatch("project/updateSettingsProjectLights", lightSettings)
  }
}