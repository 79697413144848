/* eslint-disable */
import { Plugin, SectionPlane } from "@xeokit/xeokit-sdk";
import { XeokitSectionPlanesPlugin } from "@/plugins/xeokit/XeokitSectionPlanesPlugin/XeokitSectionPlanesPlugin";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";

class PickSectionPlanesPlugin extends Plugin {
  
  /**
   * @type {Array<SectionPlane>}
   */
  #_freeSectionPlanes = []
  
  /**
   * @type {{[key: string]: SectionPlane}} 
   */
  #_sectionPlanes = {}
  
  #_sectionPlanesPlugin
  #_mouseClickedSubId

  #_active = false

  constructor(viewer, cfg = {}) {
    super('PickSectionPlanes', viewer, cfg)
    this.#_sectionPlanesPlugin = new XeokitSectionPlanesPlugin(this.viewer)

    this.#_init()
  }

  #_init() {
    this.#_mouseClickedSubId = this.viewer.scene.input.on('mouseclicked', (canvasPos) => {
      if (!this.active) return

      let pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos,
        pickSurface: false
      })

      if (!pickResult) return
      if (pickResult.isSectionControl) return
      
      const sectionPlane = this.createSectionPlane(pickResult.worldPos, pickResult.worldNormal)
      sectionPlane.flipDir()
      this.showSectionPlaneControl(sectionPlane.id)

      this.fire('picked')
    })
  }

  createSectionPlane(pos, dir) {
    const sectionPlane = this.#_freeSectionPlanes.shift() || this.#_sectionPlanesPlugin.createSectionPlane()
    sectionPlane.pos = pos
    sectionPlane.dir = dir

    this.#_sectionPlanes[sectionPlane.id] = sectionPlane
    this.#_handleUpdate()

    return sectionPlane
  }

  clearSectionPlanes() {
    this.sectionPlanes.forEach(sectionPlane => {
      this.deleteSectionPlane(sectionPlane.id)
    })
  }

  deleteSectionPlane(planeId) {
    const plane = this.#_sectionPlanes[planeId]
    this.#_sectionPlanesPlugin.hideControl(planeId)
    plane.active = false

    delete this.#_sectionPlanes[planeId]

    this.#_freeSectionPlanes.push(plane)
    
    this.#_handleUpdate()
  }

  hideSectionPlaneControl(planeId) {
    this.#_sectionPlanesPlugin.hideControl(planeId)
    
    this.#_handleUpdate()
  }

  showSectionPlaneControl(planeId) {
    const plane = this.#_sectionPlanes[planeId]
    this.#_sectionPlanesPlugin.showControl(planeId)
    plane.active = true
    
    this.#_handleUpdate()
  }

  deactivateSectionPlane(planeId) {
    const plane = this.#_sectionPlanes[planeId]
    plane.active = false
    plane.id === this.shownControlSectionPlaneId && this.#_sectionPlanesPlugin.hideControl(planeId)

    this.#_handleUpdate()
  }

  activateSectionPlane(planeId) {
    const plane = this.#_sectionPlanes[planeId]
    plane.active = true

    this.#_handleUpdate()
  }

  flipSectionPlane(planeId) {
    const plane = this.#_sectionPlanes[planeId]
    plane.flipDir()

    this.#_handleUpdate()
  }

  set active(val) {
    this.#_active = !!val
    if (this.active) {
      this.viewer.scene.camera.ortho.far = 2000
      this.viewer.scene.camera.ortho.near = 0
    }
    this.#_handleUpdate()
  }

  get active() {
    return this.#_active
  }

  get sectionPlanes() {
    return Object.values(this.#_sectionPlanes)
  }

  get shownControlSectionPlaneId() {
    return this.#_sectionPlanesPlugin.getShownControl()
  }

  get shownControlSectionPlane() {
    return this.#_sectionPlanesPlugin._controls[this.shownControlSectionPlaneId]
  }

  #_handleUpdate() {
    this.fire('update', this.DTO)
  }

  get DTO() {
    return {
      active: this.active,
      sectionPlanes: this.sectionPlanes,
      shownControlSectionPlaneId: this.shownControlSectionPlaneId,
    }
  }
}

export { PickSectionPlanesPlugin }