import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

/*eslint-disable no-dupe-class-members*/
export class ElementsSettings {

  /** Установка видимости элементов.
   * 
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов.
   * @param  {Boolean} [visible = true] Видимость элементов Вкл/Выкл.
  */
  static setElementsVisible(elementUuids = [], visible = true) {
    XeokitMediator.viewer.scene.setObjectsVisible(elementUuids, visible)
  }

  /** Установка подсветки элементов.
   * 
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов.
   * @param  {Boolean} [highlight = false] Подсветка элементов Вкл/Выкл.
   * @param  {Array<Number>} [fillColor = null] Цвет подсветки элементов [r, g, b].
  */
  static setElementsHighlighted(elementUuids = [], highlight = false, fillColor = null) {
    if (fillColor !== null)
      XeokitMediator.viewer.scene.highlightMaterial.fillColor = fillColor
    XeokitMediator.viewer.scene.setObjectsHighlighted(elementUuids, highlight)
  }

  /** Установка выделения элементов.
   *
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов.
   * @param {Boolean} [select = false] Выделение элементов Вкл/Выкл.
   */
  static setElementsSelected(elementUuids = [], select = false) {
    XeokitMediator.viewer.scene.setObjectsSelected(elementUuids, select)
  }

  /** Установка цвета подсвеченных элементов.
   *
   * @param {Array<Number>} [fillColor = [0, 0, 0]] Цвет элементов [r, g, b].
   */
  static setHighlightMaterialFillColor(fillColor = [0, 0, 0]) {
    XeokitMediator.viewer.scene.highlightMaterial.fillColor = fillColor
  }

  /** Установка цвета элементов.
   * 
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов.
   * @param  {Array<Number>} [fillColor = [0, 0, 0]] Цвет элементов [r, g, b].
  */
  static setElementsColorized(elementUuids = [], fillColor = [0, 0, 0]) {
    XeokitMediator.viewer.scene.setObjectsColorized(elementUuids, fillColor)
  }

  /** Установка прозрачности элементов.
   *
   * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов.
   * @param  {Number} [opacity = 0] Степень прозрачности.
   */
  static setElementsOpacity(elementUuids = [], opacity = 0) {
    XeokitMediator.viewer.scene.setObjectsOpacity(elementUuids, opacity)
  }

  /** Установка просвечивания элементов.
   *
   * @param {Array<String>} [elementsUuids = []] Массив UUID'ов элементов.
   * @param {Boolean} [xrayed = true] Просвечивание Вкл/Выкл.
   */
  static setElementsXRayed(elementsUuids = [], xrayed = false) {
    XeokitMediator.viewer.scene.setObjectsXRayed(elementsUuids, xrayed)
  }

  /** Установка цвета рёбер просвечивающих материалов.
   *
   * @param {Array<Number>} [color = [0, 0, 0]] Цвет элементов [r, g, b].
   */
  static setXrayMaterialEdgeColor(color = [0, 0, 0]) {
    XeokitMediator.viewer.scene.xrayMaterial.edgeColor = color
  }

  /** Возвращает прозрачность граней просвечивающих материалов.
   *
   * @returns {Number|number|*}.
   */
  static get xrayMaterialFillAlpha() {
    return XeokitMediator.viewer.scene.xrayMaterial.fillAlpha
  }

  /** Установка прозрачности граней просвечивающих материалов.
   *
   * @param {Number} [fillAlpha = 0] Степень прозрачности граней.
   */
  static setXrayMaterialFillAlpha(fillAlpha = 0) {
    XeokitMediator.viewer.scene.xrayMaterial.fillAlpha = fillAlpha
  }

  /** Установка покраски просвечивающих материалов.
   *
   * @param {Boolean} [fill = true] Закрашивание Вкл/Выкл.
   */
  static setXrayMaterialFill(fill = false) {
    XeokitMediator.viewer.scene.xrayMaterial.fill = fill
  }

  /** Возвращает прозрачность граней просвечивающих материалов.
   *
   * @returns {Number|number|*}.
   */
  static get xrayMaterialEdgeAlpha() {
    return XeokitMediator.viewer.scene.xrayMaterial.edgeAlpha
  }

  /** Установка прозрачности ребер просвечивающих материалов.
   *
   * @param {Number} [edgeAlpha = 0] Степень прозрачности граней.
   */
  static setXrayMaterialEdgeAlpha(edgeAlpha = 0) {
    XeokitMediator.viewer.scene.xrayMaterial.edgeAlpha = edgeAlpha
  }

  /** Установка ширины рёбер просвечивающих материалов.
   *
   * @param {Number} [width = 0] Ширина рёбер.
   */
  static setXrayMaterialEdgesWidth(width = 1) {
    XeokitMediator.viewer.scene.xrayMaterial.edgeWidth = width
  }

  /** Установка подсветки просвечивающих материалов.
   *
   * @param {Boolean} [glowThrough = true] Подсветка Вкл/Выкл.
   */
  static setXrayMaterialGlowingThrough(glowThrough = false) {
    XeokitMediator.viewer.scene.xrayMaterial.glowThrough = glowThrough
  }

  /** Установить параметры коллизионных элементов (XRayed элементов и закрашиваемых элементов).
   *
   * @param {Array<String>} [elementsUuidsXRayed = []] Массив UUID'ов просвечивающих элементов.
   * @param {Array<String>} [elementsUuidsColorized = []] Массив UUID'ов закрашенных элементов.
   * @param {Boolean} [xrayed = true] Просвечивание Вкл/Выкл.
   * @param {Array<Number>} [color = [0, 0, 0]] Цвет элементов [r, g, b].
   * @param {Number} [fillAlpha = 0] Степень прозрачности граней.
   * @param {Number} [edgeAlpha = 0] Степень прозрачности рёбер.
   */
  static setCollisionElementsSettings(elementsUuidsXRayed = [], elementsUuidsColorized = [], xrayed = false, color = [0, 0, 0], fillAlpha = 0, edgeAlpha = 0) {
    XeokitMediator.viewer.scene.setObjectsXRayed(elementsUuidsXRayed, xrayed)
    XeokitMediator.viewer.scene.xrayMaterial.fillAlpha = fillAlpha
    XeokitMediator.viewer.scene.xrayMaterial.edgeAlpha = edgeAlpha
    XeokitMediator.viewer.scene.setObjectsColorized(elementsUuidsColorized, color)
  }

  ///** Установка свойства выбора объектов.
  // *  
  // * @param {Array<String>} [elementUuids = []] Массив UUID'ов элементов на изменение свойства pickable.
  // * @param {Boolean} [pickable = null] True, если элементы должны выделяться по нажатию, false - если не должны, null - установить исходное состояние.
  // */
  // static setObjectsPickable(elementUuids = [], pickable = null) {
  //   const defaultPickableFalseIfcType = "IfcSpace"
  //   if (pickable == null) {
  //     elementUuids.forEach(uuid => {
  //       let element = XeokitMediator.viewer.scene.objects[uuid]
  //       if (element.isObject && element.getIfcType == defaultPickableFalseIfcType) {
  //         element.pickable = false
  //       }
  //       else element.pickable = true
  //     })
  //   }
  //   else XeokitMediator.viewer.scene.setObjectsPickable(elementUuids, pickable)
  // }
}