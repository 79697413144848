import { math } from '@xeokit/xeokit-sdk'
import { ImagePlane } from './ImagePlane'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { useImagePlaneStore } from '@/pinia';
import { projectService } from '@/_services';
import { geometry } from '../plugins/geometry/geometry';
import { ImagePlaneScaleContol } from './imagePlaneScaleControl';
import store from '@/store';

/*eslint-disable no-dupe-class-members*/
export class XeokitImagePlane {

  #_uuid = null

  #_imagePlane = null

  #_sectionPlaneImage = null

  #_editImagePlaneEnable = false

  #_sectionPlaneDirListener = null
  #_sectionPlanePosListener = null

  get uuid() {
    return this.#_uuid
  }

  get sectionPlanesPlugin() {
    return XeokitMediator.ImagePlanes.sectionPlanesPlugin
  }

  get freeSectionPlane() {
    return XeokitMediator.ImagePlanes.freeSectionPlane
  }

  get editImagePlaneEnable() {
    return this.#_editImagePlaneEnable
  }

  get imagePlane() {
    return this.#_imagePlane
  }

  get imagePlaneTitle() {
    return useImagePlaneStore().imagePlaneTitle
  }

  set imagePlaneTitle(title) {
    useImagePlaneStore().setImagePlaneTitle(title)
  }

  constructor(cfg) {
    const editable = cfg.editable ?? false

    const imageSrc = cfg?.imageSrc ?? ''
    const visible = cfg?.visible ?? false
    const gridVisible = cfg?.gridVisible ?? false
    const size = cfg?.size ?? 100
    const opacity = cfg?.opacity ?? 0.75
    const quaternion = cfg?.quaternion ?? null
    const rotation = cfg?.rotation || [0, 0, 0]
    const dir = cfg?.dir ?? [0, 0, -1]
    const position = cfg?.position ?? [0, 0, 0]
    const pickable = cfg?.pickable ?? false
    const clippable = cfg?.clippable ?? false
    const collidable = cfg?.collidable ?? false
    const uuid = cfg?.uuid ?? null

    const flipDir = cfg.flipDir ?? false

    const title = cfg.title
    this.imagePlaneTitle = title
    
    this.#_uuid = uuid

    this._imagePlaneScaleControl = null

    this.#_sectionPlaneImage = this.freeSectionPlane
    this.#_sectionPlaneImage.pos = position
    this.#_sectionPlaneImage.dir = dir

    this.#_sectionPlaneImage.active = true
    // this.#_sectionPlaneImage = this.sectionPlanesPlugin.createSectionPlane({
    //   pos: position, 
    //   dir: dir
    // })

    if (flipDir) this.#_sectionPlaneImage.flipDir()

    const createImagePlane = () => {
      this.#_imagePlane = new ImagePlane(XeokitMediator.viewer.scene, {
        src: imageSrc,                         
        visible: visible,                      
        gridVisible: gridVisible,              
        size: size,                            
        position: this.#_sectionPlaneImage.pos,
        dir: this.#_sectionPlaneImage.dir,
        rotation: rotation,                    
        opacity: opacity,                      
        collidable: collidable,                
        clippable: clippable,                  
        pickable: pickable                     
      });

    }

    useImagePlaneStore().setCreatedImageSize(size)

    createImagePlane()
    
    this.sectionPlanesPlugin.showControl(this.#_sectionPlaneImage.id)

    if (quaternion) {
      this.sectionPlanesPlugin._controls[this.sectionPlanesPlugin._shownControlId]._rootNode.quaternion = quaternion
    }

    this.sectionPlanesPlugin._controls[this.sectionPlanesPlugin._shownControlId]._displayMeshes.plane.visible = false
    this.sectionPlanesPlugin._controls[this.sectionPlanesPlugin._shownControlId]._displayMeshes.planeFrame.visible = false

    if (editable) {
      XeokitMediator.ImagePlanes.setControlImagePlaneEnabled(true)
      this.#_editImagePlaneEnable = true
    }
    else {
      this.sectionPlanesPlugin._controls[this.sectionPlanesPlugin._shownControlId]._rootNode.visible = false
    }

    // --- Формат изображения определяет подход к созданию imagePlane --- //
    let type = imageSrc.substring(11, 14)
    
    let width = 0
    let height = 0
    let scale = [1, 1, 1]
    let decodedSrc = ""

    if(type == "svg") {

      decodedSrc = Buffer.from(imageSrc.slice(26), 'base64').toString('ascii') 
      const parser = new DOMParser()
      let htmlObject = parser.parseFromString(decodedSrc, 'text/html')
      let svgHtml = htmlObject.getElementsByTagName('svg')[0]

      width = parseFloat(svgHtml.getAttribute("width"))
      height = parseFloat(svgHtml.getAttribute("height"))

      scale = this.createScaleFromImageData(width, height, size)

      if (editable) {
        this.createImagePlaneScaleControl(scale, size)
        this._imagePlaneScaleControl.setPos(this.#_imagePlane.position)
      }

      this.#_sectionPlanePosListener = this.#_sectionPlaneImage.on("pos", () => {
        this.updateImagePlanePos()
      })
      this.#_sectionPlaneDirListener = this.#_sectionPlaneImage.on("dir", () => {
        this.updateImagePlanePos()
      })

      if (uuid) this.#_imagePlane.uuid = uuid
      this.#_imagePlane._image = 1
    }
    else if (type == "png") {

      let i = new Image()
      i.onload = () => {
        scale = this.createScaleFromImageData(i.width, i.height, size)

        if (editable) {
          this.createImagePlaneScaleControl(scale, size)
          this._imagePlaneScaleControl.setPos(this.#_imagePlane.position)
        }

        this.#_sectionPlanePosListener = this.#_sectionPlaneImage.on("pos", () => {
          this.updateImagePlanePos()
        })
        this.#_sectionPlaneDirListener = this.#_sectionPlaneImage.on("dir", () => {
          this.updateImagePlanePos()
        })

        if (uuid) this.#_imagePlane.uuid = uuid
        this.#_imagePlane._image = 1
      }

      i.src = imageSrc
    }
    
  }

  get visible() {
    return this.#_imagePlane.visible
  }

  set visible(visible) {
    this.#_imagePlane.visible = visible
  }

  get size() {
    return this.#_imagePlane.size
  }

  set size(size) {
    this.#_imagePlane.size = size

    const createdImageSize = useImagePlaneStore().createdImageSize
    const scale = Number(size) / createdImageSize

    this._imagePlaneScaleControl._rootNode.scale = [scale, scale, scale]
  }

  get position() {
    return this.#_imagePlane.position
  }

  set position(position) {
    this._imagePlaneScaleControl?.setPos(position)
    this.#_sectionPlaneImage.pos = position
    this.#_imagePlane.position = position
  }

  

  updateImagePlanePos() {
    const rootNode = this.sectionPlanesPlugin?._controls[this.sectionPlanesPlugin._shownControlId]?._rootNode
        
    let controlPos = rootNode?.position.map( (pos, i) => pos + rootNode?.origin[i]) || [0, 0, 0]
    let controlRotate = rootNode?.rotation.map( (rotation) => (rotation * math.RADTODEG)) || [0, 0, 0]

    this.#_imagePlane.position = this.createSmallOffsetByPosDir(controlPos, this.#_sectionPlaneImage.dir)
    this._imagePlaneScaleControl?.setPos(controlPos)

    let newRotation = [controlRotate[0] - 90, -controlRotate[2], controlRotate[1]]

    let tempVec4 = math.vec4()
    let quaternion = math.eulerToQuaternion(newRotation, "XZY", tempVec4)
    
    this.#_imagePlane._node.quaternion = quaternion
    this._imagePlaneScaleControl?.setQuaternion(quaternion)

    if (!XeokitMediator.ImagePlanes.coordChangerTemp) {
      useImagePlaneStore().setControlImagePlaneCoordZ(this.position[2])
    }
    else {
      XeokitMediator.ImagePlanes.coordChangerTemp = false
    }
  }

  createImagePlaneScaleControl(scale, size) {
    this._imagePlaneScaleControl = new ImagePlaneScaleContol({
      imageScale: scale, 
      imagePlane: this.#_imagePlane,
      imageSize: size,
      sectionPlane: this.#_sectionPlaneImage
    })
  }

  createScaleFromImageData(width, height, size) {
    let scale = []
    if (width > height) {
      const aspect = height / width;
      scale = [size, 1.0, size * aspect];
    } 
    else {
      const aspect = width / height;
      scale = [size * aspect, 1.0, size];
    }

    return scale
  }

  createSmallOffsetByPosDir(pos, dir) {
    let moveVec = geometry.math.mulVec3Scalar(dir, -0.005)
    return geometry.math.addVec3(pos, moveVec)
  }

  // Сделать visible false
  destroy() {
    this.#_imagePlane.destroy()
    this.#_sectionPlaneImage.active = false
    this.#_sectionPlaneImage.off(this.#_sectionPlanePosListener)
    this.#_sectionPlaneImage.off(this.#_sectionPlaneDirListener)
    
    // this.#_sectionPlaneImage.destroy()
    this._imagePlaneScaleControl?.destroy()
    this._imagePlaneScaleControl = null
    this.sectionPlanesPlugin.hideControl(this.#_sectionPlaneImage.id)

    XeokitMediator.ImagePlanes.setControlImagePlaneEnabled(false)
    this.#_editImagePlaneEnable = false
  }

  cancelEdit() {
    this.sectionPlanesPlugin.hideControl(this.#_sectionPlaneImage.id)
    this.#_sectionPlaneImage.active = false
    this.#_imagePlane.destroy()
    this._imagePlaneScaleControl?.destroy()
    this._imagePlaneScaleControl = null
    XeokitMediator.ImagePlanes.setControlImagePlaneEnabled(false)
    this.#_editImagePlaneEnable = false
  }

  saveEdit(cfg) {
    const title = cfg?.title ?? ""

    projectService.saveDrawingOnModel({
      project: store.getters['project/projectUuid'],
      title: title,
      image64: useImagePlaneStore().imageSrc,
      imageSize: this.#_imagePlane.size,
      sectionPlanePos: Array.from(this.#_sectionPlaneImage.pos),
      sectionPlaneDir: Array.from(this.#_sectionPlaneImage.dir),
      sectionPlaneQuaternion: Array.from(this.sectionPlanesPlugin?._controls[this.sectionPlanesPlugin._shownControlId]?._rootNode.quaternion),
      uuid: this.#_uuid ?? null
    }).then((newImagePlaneData) => {
      projectService.getDrawingOnModelListByUuids(newImagePlaneData.data.uuid).then((newImagePlane) => {
        XeokitMediator.ImagePlanes.createImagePlane({
          uuid: newImagePlane.uuid,
          imageSrc: newImagePlane.image64,
          position: newImagePlane.sectionPlanePos,
          dir: newImagePlane.sectionPlaneDir,
          quaternion: newImagePlane.sectionPlaneQuaternion,
          size: newImagePlane.imageSize
        })
      })

      XeokitMediator.ImagePlanes.loadImagePlanesShort()
    })

    this.sectionPlanesPlugin.hideControl(this.#_sectionPlaneImage.id)
    XeokitMediator.ImagePlanes.setControlImagePlaneEnabled(false)
    this.#_sectionPlaneImage.active = false
    // this.#_sectionPlaneImage.destroy()
    this.#_imagePlane.destroy()
    this._imagePlaneScaleControl?.destroy()
    this._imagePlaneScaleControl = null
    this.#_editImagePlaneEnable = false
  }

  
}