import store from '@/store';
import { XeokitMediator } from '../XeokitMediator';
import { XeokitSectionPlanesPlugin } from '../XeokitSectionPlanesPlugin/XeokitSectionPlanesPlugin';
import { XeokitImagePlane } from './xeokitImagePlane'
import { useImagePlaneStore } from '@/pinia';
import { projectService } from '@/_services';
import { math } from '@xeokit/xeokit-sdk'

/*eslint-disable no-dupe-class-members*/
export class ImagePlanes {

  static #_imagePlanes = []

  static #_freeSectionPlane = null

  static #_sectionPlanesPlugin = null

  static get viewer() {
    return XeokitMediator.viewer
  }

  static get sectionPlanesPlugin() {
    return this.#_sectionPlanesPlugin
  }

  static get freeSectionPlane() {
    return this.#_freeSectionPlane
  }

  static get controlImagePlaneSize() {
    return this.#_imagePlaneStore.controlImagePlaneSize
  }

  static get controlImagePlaneCoordZ() {
    return this.#_imagePlaneStore.controlImagePlaneCoordZ
  }

  static get modelsMap() {
    return this.viewer.scene.models
  }

  static init() {
    this.#_sectionPlanesPlugin = new XeokitSectionPlanesPlugin(this.viewer)

    this.#_freeSectionPlane = this.#_sectionPlanesPlugin.createSectionPlane()
    this.#_freeSectionPlane.active = false
  }

  static get #_imagePlaneStore() {
    return useImagePlaneStore()
  }

  static makeSceneXrayed(xrayed) {
    void(0)
    xrayed
    // const modelIds = XeokitMediator.Models.modelIds
    // modelIds
    // console.log(XeokitMediator.viewer.scene.xrayMaterial) //= XeokitMediator.viewer.scene.selectedMaterial
    // console.log(XeokitMediator.viewer.scene.selectedMaterial)
    // XeokitMediator.viewer.scene.xrayMaterial.edgeAlpha = 1
    // XeokitMediator.viewer.scene.xrayMaterial.edgeColor = [0.3, 0.3, 0.3]
    // XeokitMediator.viewer.scene.xrayMaterial.fillAlpha = 0.3
    // XeokitMediator.viewer.scene.xrayMaterial.fillColor = [0.3, 0.3, 0.3]
    // XeokitMediator.viewer.scene.selectedMaterial.edgeAlpha = 0.2
    // XeokitMediator.viewer.scene.selectedMaterial.edgeColor = [0.3, 0.3, 0.3]
    // XeokitMediator.viewer.scene.selectedMaterial.fillAlpha = 0.2
    // XeokitMediator.viewer.scene.selectedMaterial.fillColor = [0.9, 0.9, 0.9]
    // modelIds.forEach(modelId => {
    //   console.log(this.modelsMap[modelId])
    //   this.modelsMap[modelId] && (this.modelsMap[modelId].selected = xrayed)
    // })

    // --- последний вариант --- //
    // modelIds.forEach(modelId => {
    //   console.log(this.modelsMap[modelId])
    //   this.modelsMap[modelId].entityList.forEach(entity => {
    //     entity.opacity = 0.9
    //   })
    // })


    // XeokitMediator.ElementsSettings.setXrayMaterialEdgeColor([0.3, 0.3, 0.3])
    // XeokitMediator.ElementsSettings.setXrayMaterialGlowingThrough(true)

    // XeokitMediator.Models.setModelsPickable(modelIds, !xrayed)
    // // XeokitMediator.Models.setModelsXrayed(modelIds, xrayed)
    // XeokitMediator.Models.setModelsSelected(modelIds, xrayed)
  }

  static createImagePlane(cfg) {
    this.destroyImagePlanes()
    
    const imagePlane = new XeokitImagePlane(cfg)
    this.#_imagePlanes.push(imagePlane)

    if (cfg.editable) this.makeSceneXrayed(true)

    this.#_imagePlaneStore.setSelectedImagePlaneUuid(imagePlane.uuid)
  }

  static #_getDefaultPosition() {
    let aabb = XeokitMediator.CameraFlight._defaultSceneAabb
    let topCenter = math.getAABB3Center(aabb)
    topCenter[2] = aabb[5]

    return topCenter
  }

  static createByDefaultPosition() {
    let cfg = {
      imageSrc: this.#_imagePlaneStore.imageSrc,
      position: this.#_getDefaultPosition(),
      dir: [0, 0, 1],
      editable: true,
      flipDir: true
    }

    this.#_imagePlaneStore.setWaitImagePlanePick(false)
    this.createImagePlane(cfg)
  }

  static setDefaultPosition() {
    let imgPlane = this.activeEditImagePlane
    imgPlane.freeSectionPlane.pos = this.#_getDefaultPosition()
    imgPlane.freeSectionPlane.dir = [0, 0, -1]
  }

  static async selectImagePlaneByUuid(uuid) {
    if (this.selectedImagePlaneUuid != uuid) {
      console.time("LOAD_IMAGE")
      const imagePlane = await projectService.getDrawingOnModelListByUuids(uuid)
      console.timeEnd("LOAD_IMAGE")

      this.createImagePlane({
        uuid: imagePlane.uuid,
        imageSrc: imagePlane.image64,
        position: imagePlane.sectionPlanePos,
        dir: imagePlane.sectionPlaneDir,
        quaternion: imagePlane.sectionPlaneQuaternion,
        size: imagePlane.imageSize,
        title: imagePlane.title
      })
    }
    else {
      this.destroyImagePlanes()
    }
  }

  static async deleteImagePlaneByUuid(uuid) {
    this.destroyImagePlanes()
    await projectService.deleteDrawingOnModel(uuid)
    this.loadImagePlanesShort()
  }

  static async editImagePlaneByUuid(uuid) {
    this.destroyImagePlanes()
    const imagePlane = await projectService.getDrawingOnModelListByUuids(uuid)

    this.setTempImagePlaneTitle(imagePlane.title)
    this.setTempImagePlaneSrc(imagePlane.image64)

    this.createImagePlane({
      imageSrc: imagePlane.image64,
      position: imagePlane.sectionPlanePos,
      dir: imagePlane.sectionPlaneDir,
      quaternion: imagePlane.sectionPlaneQuaternion,
      size: imagePlane.imageSize,
      editable: true,
      uuid: uuid,
      title: imagePlane.title
    })
  }

  static showImagePlaneByUuid(uuid) {
    const imagePlane = this.#_imagePlanes[uuid]
    
    this.createImagePlane({
      title: imagePlane.title,
      imageSrc: imagePlane.image.data,
      size: imagePlane.image.size,
      position: imagePlane.sectionPlane.pos,
      dir: imagePlane.sectionPlane.dir
    })
  }

  static destroyImagePlanes() {

    this.makeSceneXrayed(false)

    this.#_imagePlanes.forEach(plane => {
      plane.destroy()
    })

    this.#_imagePlanes = []

    this.#_imagePlaneStore.setSelectedImagePlaneUuid(null)
    // this.#_imagePlaneStore.setImagePlanes(this.#_imagePlanes)
  }

  static setSize(size) {
    this.#_imagePlanes[0].size = size
  }

  static get imagePlaneList() {
    return this.#_imagePlanes
  }

  static get controlImagePlaneEnabled() {
    return this.#_imagePlaneStore.controlImagePlaneEnabled
  }

  static saveEditImagePlanes(cfg) {
    this.activeEditImagePlane.saveEdit(cfg)
    // Либо не удалять, либо поменять флаг
    this.destroyImagePlanes()
  }

  static cancelEditImagePlanes() {
    this.activeEditImagePlane.cancelEdit()
    this.destroyImagePlanes()
  }

  static async loadImagePlanesShort() {
    this.imagePlanesShort = await projectService.getDrawingsOnModelShortByProject(store.getters['project/projectUuid'])
    return this.imagePlanesShort
  }

  static get activeEditImagePlane() {
    return this.#_imagePlanes.find(imagePlane => imagePlane.editImagePlaneEnable)
  }

  static get selectedImagePlaneUuid() {
    return this.#_imagePlaneStore.selectedImagePlaneUuid
  }

  static get coordChangerTemp() {
    return this.#_imagePlaneStore.coordChangerTemp
  }

  static get imagePlanesShort() {
    return this.#_imagePlaneStore.imagePlanesShort
  }

  static set imagePlanesShort(imagePlanesShort) {
    this.#_imagePlaneStore.setImagePlanesShort(imagePlanesShort)
  }

  static set coordChangerTemp(value) {
    this.#_imagePlaneStore.setCoordChangerTemp(value)
  }

  static setControlImagePlaneEnabled(enable) {
    this.#_imagePlaneStore.setControlImagePlaneEnabled(enable)
  }

  static setDialogDrawerCropperShowed(showed) {
    this.#_imagePlaneStore.setDialogDrawerCropperShowed(showed)
  }

  static setTempImagePlaneTitle(title) {
    this.#_imagePlaneStore.setImagePlaneTitle(title)
  }

  static setTempImagePlaneSrc(image64) {
    this.#_imagePlaneStore.setImageSrc(image64)
  }

  static reset() {
    this.destroyImagePlanes()
    XeokitMediator.PickImagePlane.cancelWaitImagePlanePick()
    this.setControlImagePlaneEnabled(false)
    this.setDialogDrawerCropperShowed(false)
  }
}