import { math, Mesh, PhongMaterial, ReadableGeometry, buildSphereGeometry, buildCylinderGeometry, EmphasisMaterial, Node } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '../XeokitMediator'
import { useImagePlaneStore as imagePlaneStore} from '@/pinia'
import { geometry } from '../plugins/geometry/geometry'

const zeroVec = new Float32Array([0, 0, 1])
const quat = new Float32Array(4)

export class ImagePlaneScaleContol {

  /**Создает элементы управления масштабом на сцене для ImagePlane. При создании необходим imagePlane.node.scale.
   *
   * @param {*} cfg Конфиг
   * @param {Object} cfg.imagePlane ImagePlane, к которому применяется ImagePlaneScaleControl
   * @param {Object} cfg.sectionPlane SectionPlane, который применяется к ImagePlane
   * @param {Array<Number>} cfg.imageScale Scale экземпляра ImagePlane. Необходим отдельно и заранее, так как участвует в установке вершин
   * 
   */
  constructor(cfg) {
    this.id = null;

    this._viewer = XeokitMediator.viewer

    this._visible = true
    this._pos = math.vec3()
    this._quaternion = math.vec4()
    this._baseDir = math.vec3()
    this._rootNode = null
    this._displayMeshes = null
    this._affordanceMeshes = null

    // Данные по imagePlane и sectionPlane
    this._imageScale = cfg.imageScale ?? [1, 1, 1]
    this._sectionPlane = cfg.sectionPlane
    this._imagePlane = cfg.imagePlane
    this._imageSize = cfg.imageSize

    this._createNodes()
    this._bindEvents();
  }

  setPos(pos) {
    this._pos.set(pos)
    this._rootNode.position = pos
  }

  setDir(dir) {
    this._baseDir.set(dir)
    this._rootNode.quaternion = math.vec3PairToQuaternion(zeroVec, dir, quat)
  }

  setQuaternion(quaternion) {
    this._quaternion.set(quaternion || [0, 0, 0, 1])
    this._rootNode.quaternion = this._quaternion
  }

  _createNodes() {
    const NO_STATE_INHERIT = false

    let scale = this._imageScale

    //TODO Надо найти наименьший (текущий вариант может дать сбой)
    
    let longHeight = 0.0625 * scale[0]
    let shortHeight = 0.0625 * scale[2]
    let pickRadius = 0
    if (scale[0] >= scale[2]) {
      pickRadius = longHeight
    }
    else {
      pickRadius = shortHeight
    }

    let radius = this._imageSize * 0.0075
    let bigRadius = this._imageSize * 0.01

    this._positions = {
      leftBottomPos: [-0.5, 0, -0.5],
      rightBottomPos: [0.5, 0, -0.5],
      leftTopPos: [-0.5, 0, 0.5],
      rightTopPos: [0.5, 0, 0.5],

      leftBottomPosTubeA: [-0.46875, 0, -0.5],
      leftBottomPosTubeB: [-0.5, 0, -0.46875],
      rightBottomPosTubeA: [0.46875, 0, -0.5],
      rightBottomPosTubeB: [0.5, 0, -0.46875],

      leftTopPosTubeA: [-0.46875, 0, 0.5],
      leftTopPosTubeB: [-0.5, 0, 0.46875],
      rightTopPosTubeA: [0.46875, 0, 0.5],
      rightTopPosTubeB: [0.5, 0, 0.46875],

      leftTopPosPointA: [-0.4375, 0, 0.5],
      leftTopPosPointB: [-0.5, 0, 0.4375],
      rightTopPosPointA: [0.4375, 0, 0.5],
      rightTopPosPointB: [0.5, 0, 0.4375],

      leftBottomPosPointA: [-0.4375, 0, -0.5],
      leftBottomPosPointB: [-0.5, 0, -0.4375],
      rightBottomPosPointA: [0.4375, 0, -0.5],
      rightBottomPosPointB: [0.5, 0, -0.4375],
    }

    for (let key in this._positions) {
      for (let i = 0; i < 3; i++) {
        this._positions[key][i] *= scale[i]
      }
    }

    this._rootNode = new Node(XeokitMediator.viewer.scene, {
      position: [0, 0, 0],
      scale: [1, 1, 1],
    })

    const rootNode = this._rootNode

    const materials = {
      black: new PhongMaterial(XeokitMediator.viewer.scene, {
        diffuse: [0.0, 0.0, 0.0],
        emissive: [0.0, 0.0, 0.0],
        ambient: [0.0, 0.0, 0.0],
        specular: [0.6, 0.6, 0.3],
        shininess: 80,
        lineWidth: 2,
      }),

      invisible: new PhongMaterial(rootNode, {
        diffuse: [1, 1, 0],
        alpha: 0, 
        alphaMode: "blend"
      }),

      blue: new PhongMaterial(rootNode, {
        diffuse: [0.0, 0.0, 1],
        emissive: [0.0, 0.0, 1],
        ambient: [0.0, 0.0, 0.0],
        specular: [0.6, 0.6, 0.3],
        shininess: 80,
        lineWidth: 2,
      }),

      highlightBlack: new EmphasisMaterial(rootNode, {
        edges: true,
        fill: true,
        fillColor: [0, 0, 0],
        fillAlpha: 1,
      }),

      highlightBlue: new EmphasisMaterial(rootNode, {
        edges: false,
        fill: true,
        fillColor: [0, 0, 1],
        fillAlpha: 0.0,
      }),
    }

    const geometries = {
      frameGeometry: new ReadableGeometry( rootNode, {
        primitive: 'line-loop',
        positions: [
          ...this._positions.leftTopPos,
          ...this._positions.leftBottomPos,
          ...this._positions.rightBottomPos,
          ...this._positions.rightTopPos,
        ],
        indices: [0, 1, 2, 3],
      }),

      pickPointGeometry: new ReadableGeometry( rootNode, buildSphereGeometry({
        radius: pickRadius,
      })),

      pointGeometry: new ReadableGeometry( rootNode, buildSphereGeometry({
        radius: radius,
      })),

      bigPointGeometry: new ReadableGeometry( rootNode, buildSphereGeometry({
        radius: bigRadius,
      })),
      
      longTubeGeometry: new ReadableGeometry( rootNode, buildCylinderGeometry({
        radiusTop: radius,
        radiusBottom: radius,
        height: longHeight,
        radialSegments: 18,
        heightSegments: 1,
        openEnded: false,
      })),

      longBigTubeGeometry: new ReadableGeometry( rootNode, buildCylinderGeometry({
        radiusTop: bigRadius,
        radiusBottom: bigRadius,
        height: longHeight,
        radialSegments: 18,
        heightSegments: 1,
        openEnded: false,
      })),

      shortTubeGeometry: new ReadableGeometry( rootNode, buildCylinderGeometry({
        radiusTop: radius,
        radiusBottom: radius,
        height: shortHeight,
        radialSegments: 18,
        heightSegments: 1,
        openEnded: false,
      })),

      shortBigTubeGeometry: new ReadableGeometry( rootNode, buildCylinderGeometry({
        radiusTop: bigRadius,
        radiusBottom: bigRadius,
        height: shortHeight,
        radialSegments: 18,
        heightSegments: 1,
        openEnded: false,
      })),
    }

    this._displayMeshes = {

      //TODO Прямоугольное обрамление
      frame: {
        frame: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.frameGeometry,
          material: materials.black,
          highlighted: true,
          highlightMaterial: materials.highlightBlack,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
      },

      // ---------

      leftBottomControl: {
        pickMesh: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pickPointGeometry,
          position: this._positions.leftBottomPos,
          material: materials.invisible,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),

        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftBottomPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.leftBottomPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.leftBottomPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftBottomPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftBottomPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
      },

      //----------

      rightBottomControl: {
        pickMesh: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pickPointGeometry,
          position: this._positions.rightBottomPos,
          material: materials.invisible,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),

        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightBottomPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.rightBottomPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.rightBottomPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightBottomPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightBottomPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
      },

      //---------

      leftTopControl: {
        pickMesh: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pickPointGeometry,
          position: this._positions.leftTopPos,
          material: materials.invisible,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),

        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftTopPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.leftTopPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.leftTopPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftTopPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.leftTopPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
      },

      //----------
      
      rightTopControl: {
        pickMesh: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pickPointGeometry,
          position: this._positions.rightTopPos,
          material: materials.invisible,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),

        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightTopPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.rightTopPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.rightTopPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightTopPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.pointGeometry,
          position: this._positions.rightTopPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: true,
          collidable: true,
          clippable: false,
          visible: true,
        }), NO_STATE_INHERIT),
      }
    }

    this._affordanceMeshes = {

      leftBottomBigControl: {
        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftBottomPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longBigTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.leftBottomPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortBigTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.leftBottomPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftBottomPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftBottomPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
      },

      //----------

      rightBottomBigControl: {
        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightBottomPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longBigTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.rightBottomPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortBigTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.rightBottomPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightBottomPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightBottomPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
      },

      //---------

      leftTopBigControl: {
        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftTopPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longBigTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.leftTopPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortBigTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.leftTopPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftTopPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.leftTopPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
      },

      //----------
      
      rightTopBigControl: {
        vertexPoint: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightTopPos,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.longBigTubeGeometry,
          rotation: [0, 0, 90],
          position: this._positions.rightTopPosTubeA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        tubeB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.shortBigTubeGeometry,
          rotation: [90, 0, 0],
          position: this._positions.rightTopPosTubeB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointA: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightTopPosPointA,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
  
        pointB: rootNode.addChild( new Mesh(rootNode, {
          geometry: geometries.bigPointGeometry,
          position: this._positions.rightTopPosPointB,
          material: materials.blue,
          highlighted: true,
          highlightMaterial: materials.highlightBlue,
          pickable: false,
          collidable: true,
          clippable: false,
          visible: false,
        }), NO_STATE_INHERIT),
      }
    }
  }

  _bindEvents() {
    const self = this

    var grabbed = false

    const DRAG_ACTIONS = {
      none: -1,
      ltScale: 0,
      lbScale: 1,
      rtScale: 2,
      rbScale: 3
    }

    var nextDragAction = null // As we hover grabbed an arrow or hoop, self is the action we would do if we then dragged it.
    var dragAction = null // Action we're doing while we drag an arrow or hoop.
    const lastCanvasPos = math.vec2()

    const canvas = this._viewer.scene.canvas.canvas
    const camera = this._viewer.camera
    const scene = this._viewer.scene

    const getClickCoordsWithinElement = (function () {
      const canvasPos = new Float64Array(2)
      return function (event) {
        if (!event) {
          event = window.event
          canvasPos[0] = event.x
          canvasPos[1] = event.y
        } 
        else {
          var element = event.target
          var totalOffsetLeft = 0
          var totalOffsetTop = 0

          while (element.offsetParent) {
            totalOffsetLeft += element.offsetLeft
            totalOffsetTop += element.offsetTop
            element = element.offsetParent
          }
          canvasPos[0] = event.pageX - totalOffsetLeft
          canvasPos[1] = event.pageY - totalOffsetTop
        }
        return canvasPos
      }
    })()

    const scaleImagePlane = (function() {
      
      XeokitMediator.viewer.scene.camera.ortho.far = 2000
      XeokitMediator.viewer.scene.camera.ortho.near = 0.01
      const p1 = math.vec3()
      const p2 = math.vec3()

      const xBaseAxis = math.vec3([1, 0, 0])
      const zBaseAxis = math.vec3([0, 0, 1])

      const xWorldAxis = math.vec4()
      const zWorldAxis = math.vec4()

      return function (fromMouse, toMouse, vec) {
        
        localToWorldVec(xBaseAxis, xWorldAxis) 
        localToWorldVec(zBaseAxis, zWorldAxis) 

        let viewMatrix = camera.viewMatrix
        const planeNormal2 = [viewMatrix[2], viewMatrix[6], viewMatrix[10]]
        
        getPointerPlaneIntersect(fromMouse, planeNormal2, p1)
        getPointerPlaneIntersect(toMouse, planeNormal2, p2)

        let normVec = geometry.math.normalizeVec3(vec)

        math.subVec3(p2, p1)
        
        let xzDot = math.dotVec3(p2, normVec) / 2 // Для динамической скорости стоит использовать постоянной обновляющийся сайз

        if (XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
          
          let xzOffset = geometry.math.mulVec3Scalar(normVec, xzDot)

          let newFullVec = geometry.math.addVec3(vec, xzOffset)
          let vecRatio = geometry.math.lenVec3(newFullVec) / geometry.math.lenVec3(vec)

          let newSectionPos = new Array(3)
          let newScale = self._rootNode.scale
          let oldSectionPos = self._sectionPlane.pos

          for (let i = 0; i < xzOffset.length; i++) {
            newScale[i] *= vecRatio
            newSectionPos[i] = oldSectionPos[i] + xzOffset[i]
          }
          self._imagePlane.size *= vecRatio
          imagePlaneStore().setControlImagePlaneSize(self._imagePlane.size)
          self._rootNode.scale = newScale
          self._sectionPlane.pos = newSectionPos

          // ------------ ВАРИАНТ С ДВИЖЕНИЕМ КАЖДОГО МЕША ОТДЕЛЬНО ------------------ //

          // for (let key in meshes) {
          //   if (key == "lastDisplayMesh" || key == "lastAffordanceMesh") {
          //     console.log(sectionPlaneNode)
          //     console.log(sectionPlaneNode.origin)
          //     // let oldSectionPos = sectionPlane.pos
          //     let oldSectionPos = sectionPlaneNode.origin
          //     for (let i = 0; i < zOffset.length; i++) {
          //       // Offset - потому что position наследуется от ноды
          //       meshes[key].offset[i] += xzOffset[i]
          //       newSectionPos[i] = oldSectionPos[i] + xzOffset[i] / 4
          //       // meshes[key].offset[i] += xOffset[i]
          //       // meshes[key].offset[i] += zOffset[i]
          //     }
          //     // Origin - потому что в подписке в xeokitImagePlane читается origin
          //     sectionPlaneNode.origin = newSectionPos
          //   }
          //   if (key == "lastDisplayMeshNeighbourX" || key == "lastAffordanceMeshNeighbourX") {
          //     for (let i = 0; i < zOffset.length; i++) {
          //       meshes[key].offset[i] += zOffset[i]
          //     }
          //   }
          //   if (key == "lastDisplayMeshNeighbourZ" || key == "lastAffordanceMeshNeighbourZ") {
          //     for (let i = 0; i < zOffset.length; i++) {
          //       meshes[key].offset[i] += xOffset[i]
          //     }
          //   }
            
          //   meshes[key].scale = self._rootNode.scale // для перерисовки
          // }
        } 
        else {
          const difference = math.vec3([0, 0, 0])
          const camEye = camera._eye
          const tempDif = math.vec3()
          math.subVec3(scene.camera.eye, self._pos, tempDif)
          const difLen = math.lenVec3(tempDif)
          const newCamEye = [
            camEye[0] + (20000 * tempDif[0]) / -difLen,
            camEye[1] + (20000 * tempDif[1]) / -difLen,
            camEye[2] + (20000 * tempDif[2]) / -difLen,
          ]

          math.subVec3(newCamEye, self._pos, difference)
          const dist = math.lenVec3(difference)

          xzDot = xzDot * (20000 / dist)

          let xzOffset = geometry.math.mulVec3Scalar(normVec, xzDot)

          let newFullVec = geometry.math.addVec3(vec, xzOffset)
          let vecRatio = geometry.math.lenVec3(newFullVec) / geometry.math.lenVec3(vec)

          let newSectionPos = new Array(3)
          let newScale = self._rootNode.scale
          let oldSectionPos = self._sectionPlane.pos

          for (let i = 0; i < xzOffset.length; i++) {
            newScale[i] *= vecRatio
            newSectionPos[i] = oldSectionPos[i] + xzOffset[i]
          }
          self._imagePlane.size *= vecRatio
          imagePlaneStore().setControlImagePlaneSize(self._imagePlane.size)
          self._rootNode.scale = newScale
          self._sectionPlane.pos = newSectionPos

        }
      }
    })()

    const localToWorldVec = (function () {
      const mat = math.mat4()
      return function (localVec, worldVec) {
        math.quaternionToMat4(self._rootNode.quaternion, mat)
        math.transformVec3(mat, localVec, worldVec)
        math.normalizeVec3(worldVec)
        return worldVec
      }
    })()

    var getPointerPlaneIntersect = (function () {
      const rayOrigin = math.vec4([0, 0, 0, 1])
      const matrix = math.mat4()
      return function (mouse, planeAxis, dest, offset) {
        offset = offset || 0
        const { height: realHeight, width: realWidth } = canvas.getBoundingClientRect()
        rayOrigin[0] = mouse[0] * (2 / realWidth) - 1.0
        rayOrigin[1] = -mouse[1] * (2 / realHeight) + 1.0
        rayOrigin[2] = 0.0
        rayOrigin[3] = 1.0
        math.mulMat4(camera.projMatrix, camera.viewMatrix, matrix)
        math.inverseMat4(matrix)
        math.transformVec4(matrix, rayOrigin, rayOrigin)
        math.mulVec4Scalar(rayOrigin, 1 / rayOrigin[3])

        if (XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
          const rayDir = camera._eye
          const planeOrigin = self._pos
          
          math.subVec4(rayOrigin, rayDir, rayOrigin)

          const d = -math.dotVec3(planeOrigin, planeAxis) - offset
          const dot = math.dotVec3(planeAxis, rayOrigin)
          if (Math.abs(dot) > 0.00000001) {
            const t = -(math.dotVec3(planeAxis, rayDir) + d) / dot
            math.mulVec3Scalar(rayOrigin, t, dest)
            math.addVec3(dest, rayDir)
            math.subVec3(dest, planeOrigin, dest)

            return true
          }
          return false
        } 
        else {
          const camEye = camera._eye
          const difference = math.vec3()
          math.subVec3(scene.camera.eye, self._pos, difference)
          const difLen = math.lenVec3(difference)
          const rayDir = [
            camEye[0] + (20000 * difference[0]) / -difLen,
            camEye[1] + (20000 * difference[1]) / -difLen,
            camEye[2] + (20000 * difference[2]) / -difLen,
          ]

          const planeOrigin = self._pos

          math.subVec4(rayOrigin, rayDir, rayOrigin)

          const d = -math.dotVec3(planeOrigin, planeAxis) - offset
          const dot = math.dotVec3(planeAxis, rayOrigin)

          if (Math.abs(dot) > 0.00000001) {
            const t = -(math.dotVec3(planeAxis, rayDir) + d) / dot
            math.mulVec3Scalar(rayOrigin, t, dest)
            math.addVec3(dest, rayDir)
            math.subVec3(dest, planeOrigin, dest)

            return true
          }
          return false
        }
      }
    })()

    {
      var mouseDownLeft
      var mouseDownMiddle
      var mouseDownRight
      var down = false

      var lastAffordanceMesh
      
      mouseDownLeft, mouseDownMiddle, mouseDownRight

      this._onCameraControlHover = this._viewer.cameraControl.on('hoverEnter', (hit) => {
        
        if (!this._visible) { return }
        if (down) { return }

        grabbed = false
        if (lastAffordanceMesh) { lastAffordanceMesh.visible = false }

        var affordanceMesh

        const meshId = hit.entity.id
        switch (meshId) {

          case this._displayMeshes.leftTopControl.pickMesh.id:

            affordanceMesh = this._affordanceMeshes.leftTopBigControl

            nextDragAction = DRAG_ACTIONS.ltScale
            break

          case this._displayMeshes.leftBottomControl.pickMesh.id:

            affordanceMesh = this._affordanceMeshes.leftBottomBigControl

            nextDragAction = DRAG_ACTIONS.lbScale
            break

          case this._displayMeshes.rightTopControl.pickMesh.id:

            affordanceMesh = this._affordanceMeshes.rightTopBigControl

            nextDragAction = DRAG_ACTIONS.rtScale
            break

          case this._displayMeshes.rightBottomControl.pickMesh.id: 

            affordanceMesh = this._affordanceMeshes.rightBottomBigControl

            nextDragAction = DRAG_ACTIONS.rbScale
            break

          default:
            nextDragAction = DRAG_ACTIONS.none
            return // Not clicked an arrow or hoop
        }
        if (affordanceMesh) {
          for(let key in affordanceMesh) {
            affordanceMesh[key].visible = true
          }
        }
        lastAffordanceMesh = affordanceMesh

        grabbed = true
      })

      this._onCameraControlHoverLeave = this._viewer.cameraControl.on('hoverOut', () => {
        if (!this._visible) { return }
        if (lastAffordanceMesh) { 
          for(let key in lastAffordanceMesh) {
            lastAffordanceMesh[key].visible = false
          } 
        }

        lastAffordanceMesh = null
        // lastAffordanceMeshNeighbourX = null
        // lastAffordanceMeshNeighbourZ = null
        // lastDisplayMesh = null
        // lastDisplayMeshNeighbourX = null
        // lastDisplayMeshNeighbourZ = null

        nextDragAction = DRAG_ACTIONS.none
      })

      canvas.addEventListener( 'mousedown', (this._canvasMouseDownListener = (e) => {
        e.preventDefault()
        if (!this._visible) {
          return
        }
        if (!grabbed) {
          return
        }
        this._viewer.cameraControl.pointerEnabled = false
        switch (e.which) {
          case 1: // Left button
            mouseDownLeft = true
            down = true
            var canvasPos = getClickCoordsWithinElement(e)
            dragAction = nextDragAction
            lastCanvasPos[0] = canvasPos[0]
            lastCanvasPos[1] = canvasPos[1]
            break

          default:
            break
        }
      }))

      canvas.addEventListener( 'mousemove', (this._canvasMouseMoveListener = (e) => {
        
        if (!this._visible) { return }
        if (!down) { return }

        var canvasPos = getClickCoordsWithinElement(e)
        const x = canvasPos[0]
        const y = canvasPos[1]
        
        let tempVec4 = math.vec4()
        let pos = null
        let posTransformed = null
        let vec = null

        switch (dragAction) {
          case DRAG_ACTIONS.rtScale:

            pos = this._positions.rightTopPos
            pos.push(1)
            posTransformed = math.transformPoint4(this._rootNode.matrix, pos, tempVec4)
            vec = geometry.math.subVec3(posTransformed, this._rootNode.position)
            
            scaleImagePlane(lastCanvasPos, canvasPos, vec)

            break
          case DRAG_ACTIONS.ltScale:

            pos = this._positions.leftTopPos
            pos.push(1)
            posTransformed = math.transformPoint4(this._rootNode.matrix, pos, tempVec4)
            vec = geometry.math.subVec3(posTransformed, this._rootNode.position)
            
            scaleImagePlane(lastCanvasPos, canvasPos, vec)

            break
          case DRAG_ACTIONS.rbScale:

            pos = this._positions.rightBottomPos
            pos.push(1)
            posTransformed = math.transformPoint4(this._rootNode.matrix, pos, tempVec4)
            vec = geometry.math.subVec3(posTransformed, this._rootNode.position)
            
            scaleImagePlane(lastCanvasPos, canvasPos, vec)

            break
          case DRAG_ACTIONS.lbScale:

            pos = this._positions.leftBottomPos
            pos.push(1)
            posTransformed = math.transformPoint4(this._rootNode.matrix, pos, tempVec4)
            vec = geometry.math.subVec3(posTransformed, this._rootNode.position)
            
            scaleImagePlane(lastCanvasPos, canvasPos, vec)

            break
        }

        lastCanvasPos[0] = x
        lastCanvasPos[1] = y
      }))

      canvas.addEventListener( 'mouseup', (this._canvasMouseUpListener = (e) => {

        if (!this._visible) { return }

        this._viewer.cameraControl.pointerEnabled = true

        if (!down) { return }

        switch (e.which) {
          case 1: // Left button
            mouseDownLeft = false
            break
          case 2: // Middle/both buttons
            mouseDownMiddle = false
            break
          case 3: // Right button
            mouseDownRight = false
            break
          default:
            break
        }
        down = false
        grabbed = false
      }))

      canvas.addEventListener( 'wheel', (this._canvasWheelListener = (e) => {
        if (!this._visible) {
          return
        }
        var delta = Math.max(-1, Math.min(1, -e.deltaY * 40))
        if (delta === 0) {
          return
        }
      }))
    }
  }

  destroy() {
    this.unbindEvents();
    this.destroyNodes();
  }

  unbindEvents() {

    const canvas = this._viewer.scene.canvas.canvas;
    const cameraControl = this._viewer.cameraControl;

    canvas.removeEventListener("mousedown", this._canvasMouseDownListener);
    canvas.removeEventListener("mousemove", this._canvasMouseMoveListener);
    canvas.removeEventListener("mouseup", this._canvasMouseUpListener);
    canvas.removeEventListener("wheel", this._canvasWheelListener);

    cameraControl.off(this._onCameraControlHover);
    cameraControl.off(this._onCameraControlHoverLeave);
  }

  destroyNodes() {
    this._rootNode.destroy();
    this._displayMeshes = {};
    this._affordanceMeshes = {};
  }

  static updateVerticesSizeFromImage(size, width, height) {
    if (width > height) {
      const aspect = height / width
      return [size, 1.0, size * aspect]
    } 
    else {
      const aspect = width / height
      return [size * aspect, 1.0, size]
    }
  }
}
