import { SectionPlanesPlugin } from "@xeokit/xeokit-sdk";
import { XeokitControl } from "./XeokitControl"
//import {Control} from "@xeokit/xeokit-sdk/src/plugins/SectionPlanesPlugin/Control";

class XeokitSectionPlanesPlugin extends SectionPlanesPlugin {

    constructor (viewer, cfg = {}) {
        super(viewer, cfg)
    }

    _sectionPlaneCreated(sectionPlane) {
        const control = (this._freeControls.length > 0) ? this._freeControls.pop() : new XeokitControl(this);
        control._setSectionPlane(sectionPlane);
        control.setVisible(false);
        this._controls[sectionPlane.id] = control;
        if (this._overview) {
            this._overview.addSectionPlane(sectionPlane);
        }
        sectionPlane.once("destroyed", () => {
            this._sectionPlaneDestroyed(sectionPlane);
        });
    }
}
export { XeokitSectionPlanesPlugin }